import { useEffect, useState } from "react";
import Table from "../../Partials/Table/table";
import { apiUrl } from "../../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import Alert from "../../Partials/Modals/Alert/alert";

const CommentApprovals = () => {
    const [page,setPage] = useState(1);
    const [values,setValues] = useState({});
    const [reason,setReason] = useState("");
    const [searchText,setSearchText] = useState("");
    const [sort,setSort] = useState(1);
    const [confirmationModalForAccept,setConfirmationModalForAccept] = useState();
    const [confirmationModalForDecline,setConfirmationModalForDecline] = useState();
    const [confirmationModalForShow,setConfirmationModalForShow] = useState();
    const [confirmationModalForDelete,setConfirmationModalForDelete] = useState();
    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;

    const [startDate, setStartDate] = useState(todayToIsoString);
    const [finishDate, setFinishDate] = useState(todayToIsoString);
    const [searchD, searchDate] = useState(false);
    const [dateParam, setDateParam] = useState("Oluşturulma");
    const [status, setStatus] = useState(null); // 1 = Onaylandi | 2 = Onay Bekliyor | 3 = Reddedildi

    const [alert,setAlert] = useState(false);
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();

    const titles = ["id","Yorum","Yıldız","Eklenme Tarihi","İşlemler"];
    const keys = ["id","attributes.comment","attributes.stars","attributes.createdAt","action"];

    useEffect(() => {
        async function fetchData() {
            let srt = sort == 1 ? 'sort=id:desc' : 'sort=id:asc';
            if (searchD) {
                srt += `&filters[$and][0][createdAt][$lt]=${finishDate}&filters[$and][1][createdAt][$gt]=${startDate}`
            }
            let url = `/api/comments?${srt}&filters[approved][$eq]=0&pagination[start]=${((page - 1) * 10)}&pagination[limit]=10`;
            if(searchText !== ""){
                url = "/api/comments?"+srt+"&filters[comment][$containsi]=" + searchText + "&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            }
            const response = await fetch(apiUrl + url,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    }
                });
            let result = await response.json();
            setValues(result);
        }
        fetchData();
    },[status, page,confirmationModalForDelete,searchText,confirmationModalForAccept,
        confirmationModalForDecline,sort,
        startDate,finishDate,searchD
    ]);

    const changeStatusForAccept = async(row) => {
        setConfirmationModalForAccept(row);
    }

    const changeStatusForDecline = async(row) => {
        setConfirmationModalForDecline(row);
    }

    const showAccepts = (row) => {
        console.log(row)
        setConfirmationModalForShow(row);
    }

    const deleteAccepts = (row) => {
        setConfirmationModalForDelete(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["comment-approvals-accept"] && userInfos.admin_access_control.controls["comment-approvals-accept"].checked){
        functions.push({function: changeStatusForAccept, tooltip: "Onayla", icon: "fa fa-check"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["comment-approvals-decline"] && userInfos.admin_access_control.controls["comment-approvals-decline"].checked){
        functions.push({function: changeStatusForDecline, tooltip: "Reddet", icon: "fa fa-close"});
    }

    useEffect(() => {
        switch(reason){
            case "accept":
                async function acceptFetchData() {
                    const data = await fetch(apiUrl+'/api/comments/' + confirmationModalForAccept.id, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            data: {
                                approved: 1
                            }
                        })
                    });
                    let result = await data.json();
                    if(result && result.data.id){
                        setConfirmationModalForAccept();
                        setAlert(true);
                    }
                }
                setPage(1);
                acceptFetchData();
                break;

            case "decline":
                async function declineFetchData() {
                    const data = await fetch(apiUrl+'/api/comments/' + confirmationModalForDecline.id, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            data: {
                                approved: 2
                            }
                        })
                    });
                    let result = await data.json();
                    if(result && result.data.id){
                        setConfirmationModalForDecline();
                        setAlert(true);
                    }
                }
                setPage(1);
                declineFetchData();
                break;
        }

    },[reason]);

    return(<>
        {alert ? <Alert title={"Operator Durumu"} description={reason == "delete" ? "Operator Silindi" : "Operator Düzenlendi."} state={alert} setState={setAlert}/> : null}
        {confirmationModalForAccept ? <ConfirmationModal title={"Onaylama"} description={"Onaylıyor musunuz?"} state={confirmationModalForAccept} setState={setConfirmationModalForAccept} setReason={setReason} reason={"accept"}/> : null}
        {confirmationModalForDecline ? <ConfirmationModal title={"Reddetme"} description={"Reddediyor musunuz?"} state={confirmationModalForDecline} setState={setConfirmationModalForDecline} setReason={setReason} reason={"decline"}/> : null}
        {confirmationModalForShow ? <ConfirmationModal title={"Operator Bilgisi Görüntüleme"} description={"Görüntülemek istediğinize emin misinz ?"} state={confirmationModalForShow} setState={setConfirmationModalForShow} setReason={setReason} reason={"show"}/> : null}
        {confirmationModalForDelete ? <ConfirmationModal title={"Operator Bilgisi Silme"} description={"Silmek istediğinize emin misinz ?"} state={confirmationModalForDelete} setState={setConfirmationModalForDelete} setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Yorum Onayları</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            {!values.data && (
                                <h1>Yükleniyor...</h1>
                            )}

                            {values.data && (
                                <Table setContent={setStatus}
                                       pageName="comment-approvals"
                                       titles={titles}
                                       values={values.data}
                                       valueTotalCount={values.meta.pagination.total}
                                       keys={keys}
                                       page={page}
                                       setPage={setPage}
                                       functions={functions}
                                       setSearchText={setSearchText}
                                       setSort={setSort}
                                       setStartDate={setStartDate}
                                       setFinishDate={setFinishDate}
                                       searchDate={searchDate}
                                       dateParam={dateParam}
                                       searchParam="Yorum"/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default CommentApprovals;